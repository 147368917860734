<template>
    <div id="realisations" class="page-wrapper page-realisations">
        <!-- Réalisations -->
        <section id="realisations" class="realisations site-max-width">
            <div class="intro-titles">
                <div class="left">
                    <h2 class="sup-title suptitle" data-inview="fadeInUp" data-delay="100" v-if="pageIsLoaded">{{ content.introBlock1.surtitre }}</h2>
                    <h3 class="small-title title" data-inview="fadeInUp" data-delay="300" v-if="pageIsLoaded"><span v-html="content.introBlock1.titre"></span></h3>
                </div>
                <div class="right" data-inview="fadeInUp" data-delay="400">
                    <form class="filter" action="" v-if="pageIsLoaded" @change="filterPage">
                        <label for="filter">{{ $t('Filtrer les réalisations par') }}</label>
                        <select name="filter" id="filter">
                            <option value="" selected>{{ $t('Choisir') }}</option>
                            <optgroup :label="$t('Expertises')">
                                <option v-for="(item, i) in globals.expertisesEntryShort.data" :key="i + '-' + item.id" :value="item.slug" :selected="activeFilter === item.slug">{{ item.title }}</option>
                            </optgroup>
                            <optgroup :label="$t('Industries')">
                                <option v-for="(item, i) in globals.secteursEntryShort.data" :key="i + '-' + item.id" :value="item.slug" :selected="activeFilter === item.slug">{{ item.title }}</option>
                            </optgroup>
                        </select>
                    </form>
                </div>
            </div>
            <div class="pictures-grid-items masonery-grid" v-if="pageIsLoaded">
                <article v-for="(item, i) in filteredRealisations" :key="'r-' + i" class="pictures-grid-item grid-item" data-inview="fadeInUp" data-delay="200">
                    <!-- <a href="#" target="_blank"> -->
                        <div class="titles">
                            <h3>{{ item.title }}</h3>
                            <p class="small-text">{{ item.description }}</p>
                        </div>
                        <img class="thumb-img" :src="item.imageSimple1.url" :alt="item.imageSimple1.titre">
                    <!-- </a> -->
                </article>
            </div>
            <!-- <div class="pagination-wrap" v-if="pageIsLoaded">
                <a href="#" class="site-btn">{{ $t('Page suivante') }}</a>
            </div> -->
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'
import Masonry from 'masonry-layout'

export default {
    name: 'Realisations',

    metaInfo() {
        return {
            meta: [
                {
                    vmid: 'robots',
                    property: 'robots',
                    content: this.activeFilter ? 'noindex, nofollow' : 'index, follow',
                },
            ]
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    if (this.$route && this.$route.query.filter) {
                        this.activeFilter = this.$route && this.$route.query.filter
                    }

                    setTimeout(() => {
                        // Activate Mansonery
                        setTimeout(function() {
                            var elem = document.querySelector('.masonery-grid');
                            const Msry = new Masonry( elem, {
                                // options
                                itemSelector: '.grid-item',
                                columnWidth: '.grid-item',
                                percentPosition: true,
                                gutter: 25
                            })

                            initInViewAnimations();

                            setTimeout(() => {
                                Msry.reloadItems()
                            }, 2755)
                        }, 555)
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            activeFilter: null,
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        filteredRealisations() {
            if (!this.pageIsLoaded) {
                return []
            }

            if (!this.activeFilter) {
                return this.globals.realisationsEntryShort.data
            }

            return this.globals.realisationsEntryShort.data.filter(i => i.relatedTo.includes(this.activeFilter))
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    mounted () {

    },

    methods: {
        filterPage(e) {
            e.target.closest('form').submit()
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
